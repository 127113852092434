<!-- 订单申诉驳回弹窗 -->
<template>
  <div>
    <el-dialog
        width="40%"
        center
        :visible="visible"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'订单申诉驳回':'订单申诉驳回'"
        @update:visible="updateVisible">
      <el-form
          ref="form"
          :model="form"
          label-width="100px">

        <!-- 表格 -->
        <!-- 数据表格 -->
        <my-table
            ref="myTable"
            :columns="columns"
            :tableData="List"
            :cellStyle="cellStyle"
            :headerCellStyle="headerCellStyle"
            :rowClassName="rowClassName"
            :loading="loading1"
        >
          <!--结算方式-->
          <template v-slot:is_pass= "{ scope }">
            <span v-if="scope.row.is_pass == 1" style="color: #53D1A1">是</span>
            <span v-else style="color: #FF2525">否</span>
          </template>
        </my-table>

        <div style="margin-top: 10px;">
          <span style="font-size: 14px;font-weight: 400;color: #7e7f8c;">其他申述理由：</span>
          <span style="font-size: 14px;font-weight: 400;color: #7e7f8c;">{{reason}}</span>
        </div>
        <!--<div style="margin-top: 10px;">-->
        <!--  <span style="font-size: 14px;font-weight: 400;color: #7e7f8c;">申诉处理说明：</span>-->
        <!--</div>-->
        <!--<div style="margin-top: 10px;background: #f7f8fa;border: 1px solid #e9eaf1;border-radius: 4px;padding: 20px;">-->
        <!--  <span style="font-size: 14px;font-weight: 400;text-align: left;color: #242832;"></span>-->
        <!--</div>-->
      </el-form>

      <div slot="footer">
        <el-button
            @click="updateVisible(false)">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="save">重新申诉
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
        width="40%"
        center
        :visible="reAppeal"
        :before-close="handleClose"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'订单申诉':'订单申诉'">

        <el-row :gutter="15">
          <el-col :span="12">
            <div style="display: flex;align-items: center;">
              <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
              <span style="font-size: 18px;font-weight: 700;color: #3f4155;">订单审核意见</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div style="display: flex;align-items: center;">
              <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
              <span style="font-size: 18px;font-weight: 700;color: #3f4155;">是否申述</span>
            </div>
          </el-col>
        </el-row>

      <el-row :gutter="15" style="margin-top: 10px;display: flex;align-items: center;" v-for="(item,index) in List" :key="index">
        <el-col :span="12">
          <span style="font-size: 16px;font-weight: 400;color: #2d2f47;margin-right: 15px;">{{item.name}}</span>
          <span style="font-size: 14px;font-weight: 400;color: #5976e1;">补扣款 {{item.figure}}</span>
        </el-col>
        <el-col :span="12">
          <div style="display: flex;align-items: center;">
            <el-radio v-model="item.is_appeal" :label="1">是</el-radio>
            <el-radio v-model="item.is_appeal" :label="0">否</el-radio>
            <el-input
                v-if="item.is_appeal == '1'"
                placeholder="请输入申述理由"
                v-model="item.reason"
                clearable>
            </el-input>
          </div>
        </el-col>
      </el-row>

      <!--<el-row :gutter="15" style="margin-top: 10px;">-->
      <!--  <el-col :span="12">-->
      <!--    <span style="font-size: 16px;font-weight: 400;color: #2d2f47;margin-right: 15px;">无车架号照片</span>-->
      <!--    <span style="font-size: 14px;font-weight: 400;color: #5976e1;">补扣款 -30</span>-->
      <!--  </el-col>-->
      <!--  <el-col :span="12">-->
      <!--    <div style="display: flex;align-items: center;">-->
      <!--      <el-radio v-model="radio" label="1">是</el-radio>-->
      <!--      <el-radio v-model="radio" label="2">否</el-radio>-->
      <!--    </div>-->
      <!--  </el-col>-->
      <!--</el-row>-->

      <div style="margin-top: 15px;margin-bottom: 15px;">
        <div style="display: flex;align-items: center;">
          <span style="width: 5px;height: 19px;background: #ff9b05;display: inline-block;margin-right: 10px;"></span>
          <span style="font-size: 18px;font-weight: 700;color: #3f4155;">其他申诉理由</span>
        </div>
        <div style="margin-top: 10px;">
          <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 5}"
              placeholder="请输入内容"
              v-model="reason1">
          </el-input>
        </div>
      </div>

      <div slot="footer">
        <el-button
            @click="cancel">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="determine">确定
        </el-button>
      </div>

    </el-dialog>

  </div>

</template>

<script>
import {
  delayAppealInfoApi,
  appeal
} from '@/api/orderModule'

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,


      // 表格数据
      List: [
      ],
      // 总条目数
      total: 0,
      //加载动画
      loading1:true,
      // 表头数据
      columns: [
        {
          label: "订单补扣款项",
          prop: "name",
          isShow: true
        },
        {
          label: "补扣款",
          prop: "figure",
          isShow: true
        },
        {
          label: "申述理由",
          prop: "reason",
          isShow: true
        },
        {
          label: "是否通过申述",
          prop: "is_pass",
          slot:'is_pass',
          isShow: true
        },
      ],

      //重新申诉弹窗
      reAppeal:false,

      radio: '1',
      input:'',
      textarea2:'',

      reason: '',

      reason1:'',

    };
  },
  watch: {
  },
  mounted() {
    this.loading1 = false;

    this.getInfos()
  },
  methods: {
    getInfos() {
      const id = this.data.id
      const odd = this.data.order_id
      delayAppealInfoApi(id, {odd: odd}).then(res => {
        this.List = res.data.list
        this.reason = res.data.reason
      }).catch(err => {
        this.$message.error(err.msg);
      })
    },
    /* 保存编辑 */
    save() {
      // this.$refs['form'].validate((valid) => {
      //   if (valid) {
      //     this.loading = true;
      //     this.updateVisible(false);
      //     this.$emit('done');
      //     this.loading = false;
      //   } else {
      //     return false;
      //   }
      // });

      //显示重新申诉弹窗
      this.reAppeal = true;
      // this.$emit('update:visible', false);
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },

    //关闭重新申诉弹窗
    handleClose(){
      this.reAppeal = false;
      this.$emit('update:visible', true);
    },
    //点击取消按钮
    cancel(){
      this.reAppeal = false;
      this.$emit('update:visible', true);
    },
    //点击确定按钮
    determine(){
      let data = {
        odd: this.data.order_id,
        data: this.List,
        reason: this.reason1
      }
      appeal(this.data.id, data).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.reAppeal = false;
          this.reason1 = '';
          this.$emit('update:visible', false);
          this.$emit('done');
          this.getInfos()
        }else {
          this.$message.error(res.msg);
        }
      }).catch(err => {
        this.$message.error(err.msg);
      })
    },

  }
}
</script>

<style scoped lang="scss">

</style>
